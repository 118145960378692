// Theme

const theme = {
  font: {
    primary: "Open Sans",
  },
  fontSize: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 24px;",
    regular: "font-size: 32px; line-height: 30px;",
    large: "font-size: 40px; line-height: 30px;",
    larger: "font-size: 48px; line-height: 50px;",
    xlarge: "font-size: 56px; line-height: 76px;",
  },
  fontWeight: {
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
  },
  color: {
    blue100: "#2380D7FF",
    blue80: "#2380D7CC",
    blue60: "#2380D799",
    blue40: "#2380D766",
    secondary: {
      yellow: "#FCBC58",
      textGray: "#414750",
      buttonGray: "#BDC6D0",
      green: "#4CC78C",
      errorState: "#E44040",
      silver: "#EFF3F8",
    },
    background: {
      white: "#ffffff",
      grey: "#EFF3F8",
    },
    white: "#ffffff",
    black: "#000000",
  },
  screen: {
    xxs: "400px",
    xs: "575px",
    sm_1: "766px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
    xlg:"1440px"
  },
}

export default theme

// import { useState, useEffect } from 'react';

// const useForm = (callback, validate) => {

//   const [values, setValues] = useState({});
//   const [errors, setErrors] = useState({});
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   useEffect(() => {
//     if (Object.keys(errors).length === 0 && isSubmitting) {
//       callback();
//     }
//   }, [errors]);

//   const handleSubmit = (event) => {
//     if (event) event.preventDefault();
//     setErrors(validate(values));
//     setIsSubmitting(true);
//   };

//   const handleChange = (event) => {
//     event.persist();
//     setValues(values => ({ ...values, [event.target.name]: event.target.value }));
//   };

//   return {
//     handleChange,
//     handleSubmit,
//     values,
//     errors,
//   }
// };

// export default useForm;